<!-- Page Header Start-->
<div class="page-main-header" [class.close_icon]="navServices.collapseSidebar">
  <div class="main-header-right row m-0">
    <form class="form-inline search-full" [class.open]="search">
      <div class="form-group w-100">
        <input class="form-control-plaintext" [(ngModel)]="text" [ngModelOptions]="{standalone: true}"
          (keyup)="searchTerm(text)" type="text" autocomplete="off" placeholder="Search..">
        <app-feather-icons [icon]="'x'" class="close-search" (click)="searchToggle()"></app-feather-icons>
        <div class="Typeahead-menu" [class.is-open]="searchResult" *ngIf="menuItems?.length" id="search-outer">
          <div class="ProfileCard u-cf" *ngFor="let menuItem of menuItems | slice:0:8">
            <div class="ProfileCard-avatar">
              <app-feather-icons [icon]="menuItem.icon" class="header-search"></app-feather-icons>
            </div>
            <div class="ProfileCard-details">
              <div class="ProfileCard-realName">
                <a [routerLink]="menuItem?.path" class="realname" (click)="removeFix()">{{menuItem?.title}}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="Typeahead-menu" [class.is-open]="searchResultEmpty">
          <div class="tt-dataset tt-dataset-0">
            <div class="EmptyMessage">
              Opps!! There are no result found.
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="main-header-left">
      <div class="logo-wrapper">
        <a routerLink='/'>
          <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="">
          <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="">
        </a>
      </div>
      <div class="toggle-sidebar" (click)="sidebarToggle()">
        <app-feather-icons [icon]="'grid'" class="status_toggle middle"></app-feather-icons>
      </div>
    </div>
    <div class="left-menu-header col horizontal-wrapper pl-0">
      <ul class="horizontal-menu">
        <li class="mega-menu">
          <div class="mega-menu-container menu-content" [class]="navServices.megaMenu ? 'd-block' : 'd-none'">
            <div class="container-fluid">
              <div class="row">
                <div class="col mobile-title d-none">
                  <h5>Mega menu</h5>
                  <app-feather-icons [icon]="'x'" (click)="megaMenuToggle()"></app-feather-icons>
                </div>
                <div class="col mega-box" *ngFor="let megaItem of megaItems">
                  <div class="link-section icon" (click)="toggletNavActive(megaItem)">
                    <div [class.active]="megaItem.active && navServices.megaMenuColapse">
                      <h6>{{megaItem.title}}</h6>
                    </div>
                    <ul [class.d-none]="!megaItem.active && navServices.megaMenuColapse" *ngIf="megaItem.children">
                      <li *ngFor="let childrenMegaItem of megaItem.children">
                        <!-- Link -->
                        <a [routerLink]="!childrenMegaItem.type ? null : [childrenMegaItem.path]"
                          *ngIf="childrenMegaItem.type === 'link'" routerLinkActive="active"
                          [routerLinkActiveOptions]="{exact: true}">
                          {{childrenMegaItem.title}}
                        </a>
                        <!-- External Link -->
                        <a href="{{ !childrenMegaItem.type ? null : childrenMegaItem.path }}"
                          *ngIf="childrenMegaItem.type === 'extLink'" routerLinkActive="active"
                          [routerLinkActiveOptions]="{exact: true}">
                          {{childrenMegaItem.title}}
                        </a>
                        <!-- External Tab Link -->
                        <a href="{{ !childrenMegaItem.type ? null : childrenMegaItem.path }}" target="_blank"
                          *ngIf="childrenMegaItem.type === 'extTabLink'">
                          {{childrenMegaItem.title}}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="nav-right col-8 pull-right right-menu">
      <ul class="nav-menus">
        <li class="profile-nav onhover-dropdown p-0">
          <div class="media profile-media">
            <div class="media-body"><span>{{user.first_name}} {{user.last_name}}</span>
              <p class="mb-0 font-roboto">Admin <i class="middle fa fa-angle-down"></i></p>
            </div>
          </div>
          <ul class="profile-dropdown onhover-show-div">
            <li [routerLink]="['/editprofile']">
              <app-feather-icons [icon]="'settings'"></app-feather-icons><span>Налаштування</span>
            </li>
            <li (click)="logout()">
              <app-feather-icons [icon]="'log-out'"></app-feather-icons>Вихід
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- Page Header Ends -->