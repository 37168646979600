import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

type UserFields = 'email' | 'password';

declare var require
const Swal = require('sweetalert2')

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public newUser = false;
  public signup: boolean;
  public loginForm: FormGroup;
  public registraionForm: FormGroup;

  public email = '';
  public password = '';

  public errors = {
    email_required: false,
    password_required: false,
  }

  constructor(private route: Router, public http:HttpClient) {
  }

  ngOnInit() {
  }

  login(){
    this.http.post<any>('https://system.cherish.com.ua/Dash/auth/login',{email:this.email, password:this.password}).subscribe(res => {
      if(res.error_code == 0){
        localStorage.setItem('token', res.data.token);
        localStorage.setItem('data', JSON.stringify(res.data));
        this.route.navigateByUrl('/test');
      } else {
        Swal.fire({
          type: 'error',
          title: 'Помилка',
          text: 'Користувач не знайдений!'
        })
      }
    });
  }

}
