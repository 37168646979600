<!-- page-wrapper Start-->
<div class="container-fluid p-0">
    <!-- login page start-->
    <div class="authentication-main">
        <div class="row">
            <div class="col-md-12">
                <div class="auth-innerright">
                    <div class="authentication-box">
                        <div>
                            <div class="card-body p-0">
                                <div class="cont text-center b-light" [ngClass]="{'s--signup': signup}">
                                    <div>
                                        <div class="theme-form">
                                            <h4>Вхід до панелі адміністратора</h4>
                                            <div class="form-group">
                                                <label class="col-form-label pt-0">Ваш email</label>
                                                <input class="form-control" type="text" required="" [(ngModel)]="email">
                                                <div class="text text-danger mt-1" *ngIf="errors.email_required">
                                                    Email обв'язковий
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-form-label">Пароль</label>
                                                <input class="form-control" type="password" required="" [(ngModel)]="password">
                                                <div class="text text-danger mt-1" *ngIf="errors.password_required">
                                                    Пароль обв'язковий
                                                </div>
                                            </div>
                                            <div class="form-group form-row mt-3 mb-0">
                                                <button class="btn btn-primary btn-block"
                                                    (click)="login()"><span>Увійти</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="sub-cont">
                                        <div class="img">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- login page end-->
</div>