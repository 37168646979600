import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

// Menu
export interface Menu {
	headTitle1?: string,
	headTitle2?: string,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnDestroy {

	private unsubscriber: Subject<any> = new Subject();
	public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

	public megaMenu: boolean = false;
	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;
	public fullScreen: boolean = false;


	MENUITEMS: Menu[] = [];

	constructor(private router: Router, public http: HttpClient) {
		this.setScreenWidth(window.innerWidth);
		fromEvent(window, 'resize').pipe(
			debounceTime(1000),
			takeUntil(this.unsubscriber)
		).subscribe((evt: any) => {
			this.setScreenWidth(evt.target.innerWidth);
			if (evt.target.innerWidth < 991) {
				this.collapseSidebar = true;
				this.megaMenu = false;
			}
			if (evt.target.innerWidth < 1199) {
				this.megaMenuColapse = true;
			}
		});
		if (window.innerWidth < 991) { // Detect Route change sidebar close
			this.router.events.subscribe(event => {
				this.collapseSidebar = true;
				this.megaMenu = false;
			});
		}

		this.http.post<any>('https://system.cherish.com.ua/Dash/auth/get_access', { token: localStorage.getItem('token') }).subscribe(res => {
			if (res.error_code == 0) {
				localStorage.setItem('access', JSON.stringify(res.data.access));
				this.generateSidebar();
			} else {
				this.MENUITEMS.splice(0, 10);
			}
		});
	}

	generateSidebar() {
		this.MENUITEMS.splice(0, 10);
		let access = JSON.parse(localStorage.getItem('access'));
		let data = JSON.parse(localStorage.getItem('data'));
		
		if (access['requests'] == 1) {

			this.MENUITEMS.push({
				title: 'Інформація', icon: 'users', path: '/requests', type: 'link'
			});
		}

		if(data['region_id'] == 1){

			this.MENUITEMS.push({
				title: 'Видалена інформація', icon: 'trash', path: '/deleted', type: 'link'
			});
		}

		if (access['posts'] == 1) {
			this.MENUITEMS.push({
				title: 'Новини', icon: 'file-text', type: 'sub', badgeType: 'success', children: [
					{ path: '/createnew', title: 'Створити новину', type: 'link' },
					{ path: '/news', title: 'Усі новини', type: 'link' }
				]
			});
		}

		if (access['admins'] == 1 && access['regions'] == 1) {
			this.MENUITEMS.push({
				title: 'Адміністратори', icon: 'eye', type: 'sub', badgeType: 'success', children: [
					{ path: '/admins', title: 'Усі адміністратори', type: 'link' },
					{ path: '/regions', title: 'Територіальні підрозділи', type: 'link' }
				]
			});
		}

		if (access['admins'] == 1 && access['regions'] == 0) {
			this.MENUITEMS.push({
				title: 'Адміністратори', icon: 'eye', type: 'sub', badgeType: 'success', children: [
					{ path: '/admins', title: 'Усі адміністратори', type: 'link' }
				]
			});
		}

		if (access['admins'] == 0 && access['regions'] == 1) {
			this.MENUITEMS.push({
				title: 'Адміністратори', icon: 'eye', type: 'sub', badgeType: 'success', children: [
					{ path: '/regions', title: 'Територіальні підрозділи', type: 'link' }
				]
			});
		}

		if (access['requests'] == 1) {
			this.MENUITEMS.push({
				title: 'Користувачі', icon: 'users', path: '/users', type: 'link'
			});
		}

		if (access['content'] == 1) {
			this.MENUITEMS.push({
				title: 'Контент', icon: 'archive', path: '/content', type: 'link'
			});
		}
	}

	ngOnDestroy() {
		this.unsubscriber.next();
		this.unsubscriber.complete();
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}

	/*
	MENUITEMS: Menu[] = [
		{
			title: 'Головна', icon: 'home', type: 'link', path: '/ecommerce', badgeType: 'success', active: true
		},
		{
			title: 'Інформація', icon: 'folder', type: 'sub', badgeType: 'success', badgeValue: '2', children: [
				{ path: '/messages', title: 'Месенджери', type: 'link' },
				{ path: '/forms', title: 'Google Forms', type: 'link' }
			]
		},
		{
			title: 'Новини', icon: 'folder', type: 'sub', badgeType: 'success', children: [
				{ path: '/createnew', title: 'Створити новину', type: 'link' },
				{ path: '/news', title: 'Усі новини', type: 'link' }
			]
		},
		{
			title: 'Адміністратори', icon: 'users', type: 'sub', badgeType: 'success',  children: [
				{ path: '/admins', title: 'Усі адміністратори', type: 'link' },
				{ path: '/regions', title: 'Територіальні підрозділи', type: 'link' }
			]
		},
		{
			title: 'Користувачі', icon: 'users', path: '/users', type: 'link'
		},
		{
			title: 'Контент', icon: 'folder', path: '/content', type: 'link'
		}
	];
	*/

	MEGAMENUITEMS: Menu[] = [
		{
			title: 'Error Pages', type: 'sub', active: true, children: [
				{ path: '/error-page/error-400', title: 'Error Page 400', type: 'link' },
				{ path: '/error-page/error-401', title: 'Error Page 401', type: 'link' },
				{ path: '/error-page/error-403', title: 'Error Page 403', type: 'link' },
				{ path: '/error-page/error-404', title: 'Error Page 404', type: 'link' },
				{ path: '/error-page/error-500', title: 'Error Page 500', type: 'link' },
				{ path: '/error-page/error-503', title: 'Error Page 503', type: 'link' },
			]
		},
		{
			title: 'Authentication', type: 'sub', active: false, children: [
				{ path: '/authentication/simple-login', title: 'Login Simple', type: 'link' },
				{ path: '/authentication/login-with-background-image', title: 'Login BG Image', type: 'link' },
				{ path: '/authentication/login-with-background-video', title: 'Login BG Video', type: 'link' },
				{ path: '/authentication/simple-register', title: 'Simple Register', type: 'link' },
				{ path: '/authentication/register-with-background-image', title: 'Register BG Image', type: 'link' },
				{ path: '/authentication/register-with-background-video', title: 'Register BG Video', type: 'link' }
			]
		},
		{
			title: 'Usefull Pages', type: 'sub', active: false, children: [
				{ path: '/search-pages', title: 'Search Pages', type: 'link' },
				{ path: '/authentication/unlock-user', title: 'Unlock User', type: 'link' },
				{ path: '/authentication/forgot-password', title: 'Forgot Password', type: 'link' },
				{ path: '/authentication/reset-password', title: 'Reset Password', type: 'link' },
				{ path: '/maintenance', title: 'Maintenance', type: 'link' }
			]
		},
		{
			title: 'Email templates', type: 'sub', active: false, children: [
				{ path: 'http://admin.pixelstrap.com/cuba/theme/basic-template.html', title: 'Basic Email', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/email-header.html', title: 'Basic With Header', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/template-email.html', title: 'Ecomerce Template', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/template-email-2.html', title: 'Email Template 2', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/ecommerce-templates.html', title: 'Ecommerce Email', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/email-order-success.html', title: 'Order Success', type: 'extTabLink' }
			]
		},
		{
			title: 'Coming Soon', type: 'sub', active: false, children: [
				{ path: '/coming-soon/simple', title: 'Coming Simple', type: 'link' },
				{ path: '/coming-soon/simple-with-bg-img', title: 'Coming BG Image', type: 'link' },
				{ path: '/coming-soon/simple-with-bg-vid', title: 'Coming BG Video', type: 'link' }
			]
		},
	];

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	megaItems = new BehaviorSubject<Menu[]>(this.MEGAMENUITEMS);

}
