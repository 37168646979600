import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  
  constructor(public router: Router, public http:HttpClient) { }

  canActivate(next: ActivatedRouteSnapshot, 
      state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Guard for user is login or not
    let token = localStorage.getItem('token');
    if (!token || token === null) {
      this.router.navigate(['/auth/login']);
      return true
    } else {
      this.http.post<any>('https://system.cherish.com.ua/Dash/auth/check_token',{token:token}).subscribe(res => {
        if(res.error_code == 0){
          return true;
        } else {
          localStorage.clear();
          window.location.reload();
        }
      });
    }
    return true
  }

  checkPage(acc){
    if(localStorage.getItem('access')){
      let access = JSON.parse(localStorage.getItem('access'));
      if(access[acc] == 0){
        this.router.navigate(['/test']);
      }
    } else {
      this.http.post<any>('https://system.cherish.com.ua/Dash/auth/get_access',{token:localStorage.getItem('token')}).subscribe(res => {
        if(res.error_code == 0){
          localStorage.setItem('access', JSON.stringify(res.data.access));
          if(res.data.access[acc] == 0){
            this.router.navigate(['/test']);
          }
        } else {
          this.router.navigate(['/auth/login']);
        }
      });
    }
  }
  
}
