import { Routes } from '@angular/router';

export const content: Routes = [
	{
		path: 'test',
		loadChildren: () => import('../../components/test-page/test-page.module').then(m => m.TestPageModule)
	},
	{
		path: 'admins',
		loadChildren:() => import('../../components/admins/admins.module').then(m => m.AdminsModule)
	},
	{
		path: 'createadmin',
		loadChildren:() => import('../../components/createadmin/createadmin.module').then(m => m.CreateadminModule)
	},
	{
		path: 'editadmin/:id',
		loadChildren:() => import('../../components/editadmin/editadmin.module').then(m => m.EditadminModule)
	},
	{
		path: 'regions',
		loadChildren:() => import('../../components/regions/regions.module').then(m => m.RegionsModule)
	},
	{
		path: 'news',
		loadChildren:() => import('../../components/news/news.module').then(m => m.NewsModule)
	},
	{
		path: 'createnew',
		loadChildren:() => import('../../components/createnew/createnew.module').then(m => m.CreatenewModule)
	},
	{
		path: 'editnew/:id',
		loadChildren:() => import('../../components/editnew/editnew.module').then(m => m.EditnewModule)
	},
	{
		path: 'content',
		loadChildren:() => import('../../components/content/content.module').then(m => m.ContentModule)
	},
	{
		path: 'editcontent/:id',
		loadChildren:() => import('../../components/editcontent/editcontent.module').then(m => m.EditcontentModule)
	},
	{
		path: 'users',
		loadChildren:() => import('../../components/users/users.module').then(m => m.UsersModule)
	},
	{
		path: 'requests',
		loadChildren:() => import('../../components/requests/requests.module').then(m => m.RequestsModule)
	},
	{
		path: 'deleted',
		loadChildren:() => import('../../components/deleted/deleted.module').then(m => m.DeletedModule)
	},
	{
		path: 'editprofile',
		loadChildren:() => import('../../components/editprofile/editprofile.module').then(m => m.EditprofileModule)
	}
];
